import "../scss/style.scss";

import { createPopper } from '@popperjs/core';
import * as bootstrap from 'bootstrap'
import { Collapse } from 'bootstrap';
var Mustache = require('mustache');

let body = document.body;

document.addEventListener("DOMContentLoaded", function () {
    let node = document.querySelector('.preload-transitions');
    node.classList.remove('preload-transitions');
});

// Hamburger
let hamburger = document.querySelector(".site-header__hamburger");
if (hamburger) {
    hamburger.addEventListener("click", (e) => {
        e.preventDefault();
        body.classList.toggle("nav-open");
    });
}

// Form | Search
let form_search = document.getElementById("form--search-mini");
if (form_search && form_search.length) {
    let enableSubmit = false;
    let form_btn_submit = document.querySelector(".form-btn[data-action='submit']");
    let form_btn_close = document.querySelector(".form-btn[data-action='close']");
    let form_input = document.querySelector(".form-control--search-mini");
    let form_input_value = form_input.value;

    if (form_input_value.length != 0) {
        form_btn_submit.classList.remove("is-inactive");
        body.classList.add("form--search-mini-open");
        enableSubmit = true;
    } else {
        form_btn_submit.classList.add("is-inactive");
        body.classList.remove("form--search-mini-open");
        enableSubmit = false;
    }

    if (form_btn_submit) {
        form_btn_submit.addEventListener("click", (e) => {
            form_input_value = form_input.value;
            e.preventDefault();
            if (form_input_value.length != 0) {
                form_search.submit();
                e.preventDefault();
            } else {
                form_btn_submit.classList.remove("is-inactive");
                body.classList.add("form--search-mini-open");
                setTimeout(function () {
                    form_input.focus();
                }, 200);
                e.preventDefault();
            }

        });

        form_btn_close.addEventListener("click", (e) => {
            form_btn_submit.classList.add("is-inactive");
            body.classList.remove("form--search-mini-open");
            form_input.value = "";
            e.preventDefault();
        });
    }

    form_input.onkeydown = function (e) {
        if (e.keyCode == 13) {
            form_search.submit();
        }
    };
}

// Custom Gravity Forms
let form = document.getElementsByTagName('form');
let url = window.location.href;

if(form.length) {
    let select = document.querySelector('.ginput_container.ginput_container_select select');

    if(url.indexOf("ondernemen") > -1) {
        document.getElementById('input_1_4').value = 'Ondernemen';
    }

    else if(url.indexOf("wonen") > -1) {
        document.getElementById('input_1_4').value = 'Wonen';
    }

    else if(url.indexOf("leven") > -1) {
        document.getElementById('input_1_4').value = 'Leven';
    }
    else if(url.indexOf("overlijden") > -1) {
        document.getElementById('input_1_4').value = 'Overlijden';
    }
}

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
        if (comparer(this[i])) return true;
    }
    return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
    if (!this.inArray(comparer)) {
        this.push(element);
    }
};


// Videos
let videos = document.querySelectorAll(".media--video");
if (videos.length) {

    let videoIds = [];

    let ytVideoTag = document.createElement('script');
    ytVideoTag.src = "https://s.ytimg.com/yts/jsbin/www-widgetapi-vflioRwVn/www-widgetapi.js";
    ytVideoTag.setAttribute('onload', 'onYouTubeIframeReady()');
    let ytVideoTagFirstScriptTag = document.getElementsByTagName('script')[0];
    ytVideoTagFirstScriptTag.parentNode.insertBefore(ytVideoTag, ytVideoTagFirstScriptTag);
    videos.forEach(function (video) {
        // Define variables
        let videoBtn = video.getElementsByClassName("js-open-video-inline");
        let videoIframe = video.getElementsByClassName("media__src--video");
        let videoId = videoIframe[0].getAttribute("id");

        // Push all video's to videoIds array
        videoIds.push(videoId);

        videoBtn[0].addEventListener("click", (e) => {

            // Remove image and button if exists
            video.classList.toggle("is-inactive");

            // Play video on btn click
            playVid(videoId);
            e.preventDefault();
        });

    });

    //
    window.onYouTubeIframeReady = function () {

        videoIds.forEach(function (videoId) {

            window['yt-video-' + videoId + '-player'] = new YT.Player(videoId, {
                events: {
                    'onReady': onPlayerReady,
                },
                playerVars: { rel: 0, showinfo: 0, ecver: 2 }
            });

        })

    }


}

// let btn = document.getElementById("testbtn");
// btn.addEventListener("click", function () {
//     document.addEventListener('facetwp-refresh', function () {
//         do_this();
//     });
// });

// function do_this() {
//     FWP.facets['continent'] = []; // force a specific value
// // }
// document.addEventListener('facetwp-refresh', function () {
//     if (null !== FWP.active_facet) {
//         if (FWP.active_facet.nodes[0].dataset.name == 'continent') {
//             // FWP.reset(['europe', 'asia']);
//             console.log(FWP);
//             FWP.facets['europe'] = [];
//             FWP.facets['asia'] = [];
//             // FWP.reset('asia');
//             // FWP.reset('europe');
//             // delete FWP.facets['europe', 'asia'];
//         }
//     }
//     console.log(FWP.build_query_string());
// });

let filter_pager = document.querySelector(".filters__pager");
document.addEventListener('facetwp-loaded', function () {
    if (filter_pager && (FWP.settings.pager.total_pages == 1 || FWP.settings.pager.total_pages == 0)) {
        filter_pager.classList.add("d-none");
    } else {
        filter_pager.classList.remove("d-none");
    }
});

/*

var simulateClick = function (elem) {
    // Create our event (with options)
    var evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
};

document.addEventListener('facetwp-refresh', function () {
    if (FWP.soft_refresh == true) {
        FWP.enable_scroll = true;
    } else {
        FWP.enable_scroll = false;
    }

    if (null !== FWP.active_facet) {
        if (FWP.active_facet.nodes[0].dataset.name == 'continent') {
            console.log(FWP);
            FWP.facets['asia'] = [];
            FWP.facets['europe'] = [];
            // FWP.reset('asia');
            // FWP.reset('europe');
            // console.log("Radios", document.querySelector(".fs-option[data-value='asia']"));
            simulateClick(document.querySelector(".fs-option[data-value='asia']"));
            FWP.parseFacets();
            FWP.fetchData();
        }
    }
});

// document.querySelectorAll('#test').forEach(function (el) {
//     el.addEventListener('click', function () {
//         FWP.facets['ricardo'] = "belgium";
//         // FWP.parseFacets();
//         FWP.setHash();
//         FWP.fetchData();
//         FWP.parseFacets();
//         // FWP.refresh();
//     });
// });
*/


document.addEventListener('facetwp-refresh', function () {
    if (FWP.soft_refresh == true) {
        FWP.enable_scroll = true;
    } else {
        FWP.enable_scroll = false;
    }
});

let anchor = document.querySelector('.filters__trickery');
document.addEventListener('facetwp-loaded', function () {
    if (FWP.enable_scroll == true) {
        anchor.scrollIntoView();
    }

    if (FWP.facets.system_categories) {
        let currentFilter = document.querySelectorAll(".facetwp-radio[data-value='" + FWP.facets.system_categories[0] + "']")[0];
        let currentFilterTitle = document.querySelector(".filters__title");

        if (currentFilter && currentFilter !== undefined) {
            currentFilterTitle.innerHTML = document.querySelectorAll(".facetwp-radio[data-value='" + FWP.facets.system_categories[0] + "']")[0].getAttribute("data-name");
            currentFilterTitle.classList.remove("d-none");
        } else {
            currentFilterTitle.innerHTML = "";
            currentFilterTitle.classList.add("d-none");
        }
        document.querySelector(".filters__count span").innerHTML = FWP.settings.pager.total_rows;
        if (FWP.settings.pager.total_rows || FWP.settings.pager.total_rows != 0) {
            if (FWP.settings.pager.total_rows == 1) {
                console.log(document.querySelector(".filters__count--multiple"));
                document.querySelector(".filters__count--multiple").classList.add("d-none");
                document.querySelector(".filters__count--single").classList.remove("d-none");
            } else {
                document.querySelector(".filters__count--single").classList.add("d-none");
                document.querySelector(".filters__count--multiple").classList.remove("d-none");
            }
        }
    }

});
function onPlayerReady(event) {

}

function playVid(videoId) {
    window['yt-video-' + videoId + '-player'].playVideo();
}



// let mediaQuery = window.matchMedia("(min-width: 1240px)");
// if (mediaQuery.matches) {

//     var popoverTriggerListAll = document.querySelectorAll('[data-bs-toggle="popover"]');
//     var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
//     let popover = [];
//     for (let i = 0; i < popoverTriggerList.length; i++) {
//         popover[i] = new bootstrap.Popover(document.querySelectorAll("[data-bs-toggle='popover']")[i], {
//             html: true,
//             trigger: 'manual',
//             container: ".tooltip__container",
//         });
//         // console.log(document.querySelectorAll("[data-bs-toggle='popover']")[i]);
//     }

//     var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//         popoverTriggerEl.addEventListener('show.bs.popover', function () {
//             popoverTriggerEl.classList.add("is-active");
//         });
//         popoverTriggerEl.addEventListener('hidden.bs.popover', function () {
//             // popoverTriggerList.forEach(function (el, index) {
//             //     popoverTriggerListAll[index].classList.remove("is-active");
//             // });
//             popoverTriggerEl.classList.remove("is-active");
//         });
//     })


//     popoverTriggerList.forEach(function (el, index) {
//         el.addEventListener("click", function (event, index2) {
//             // popoverTriggerListAll[index].classList.add("is-active");
//             popover.forEach(function (popover) {
//                 popover.hide();
//             });
//             popover[index].show();
//         });
//     });

//     let defaultImage = document.getElementById("mapped_image");
//     let defaultImageSrc = defaultImage.getAttribute('src');
//     let imageArea = document.querySelectorAll('.image-map__area');
//     if (defaultImageSrc && imageArea) {
//         imageArea.forEach(function (area) {
//             area.addEventListener("click", function (e) {
//                 e.preventDefault();
//                 console.log(area.getAttribute("title"));
//                 document.getElementById("tooltip-" + area.getAttribute("title")).click();
//                 // console.log(popoverTriggerList[3]);
//             });
//             area.addEventListener("mouseover", function () {
//                 let useImage = area.getAttribute('useimage');
//                 defaultImage.setAttribute('src', useImage);
//             });
//             area.addEventListener("mouseout", function () {
//                 defaultImage.setAttribute('src', defaultImageSrc);
//             });
//         });
//     }


//     //Moving clicks to the area's instead of the buttons


//     // $('area.cooling').click(
//     //     function (e) {
//     //         $('.btn--tooltip').not('#tooltip-cooling').popover('hide');
//     //         $('#tooltip-cooling').popover('toggle');
//     //     }
//     // );
//     // var options = {
//     //     content: "s",
//     //     title: "meh"
//     // }
//     // var exampleEl = document.querySelector('area.cooling')
//     // var popover = new bootstrap.Popover(exampleEl, options)
//     // popover.show();
//     // $('#tooltip-cooling').popover({
//     //     //placement: "bottom",
//     //     container: ".popover__container",
//     //     html: true,
//     //     title: "<a class='btn btn--big' href='/detail'>View system</a> Cooling <a class='btn btn--small'><span>launch</span></a>",
//     //     content: `Allowing products to stabilize after depositing/sandwiching or solidify (chocolate) coating and/or decorations.`,
//     // });
//     /*
//         $('area.distribution').click(
//             function (e) {
//                 $('.btn--tooltip').not('#tooltip-distribution').popover('hide');
//                 $('#tooltip-distribution').popover('toggle');
//             }
//         );

//         $('area.flowrapping').click(
//             function (e) {
//                 $('.btn--tooltip').not('#tooltip-flowrapping').popover('hide');
//                 $('#tooltip-flowrapping').popover('toggle');
//             }
//         );

//         $('area.buffering').click(
//             function (e) {
//                 $('.btn--tooltip').not('#tooltip-buffering').popover('hide');
//                 $('#tooltip-buffering').popover('toggle');
//             }
//         );

//         $('area.loading').click(
//             function (e) {
//                 $('.btn--tooltip').not('#tooltip-loading').popover('hide');
//                 $('#tooltip-loading').popover('toggle');
//             }
//         );

//         $('area.inspection').click(
//             function (e) {
//                 $('.btn--tooltip').not('#tooltip-inspection').popover('hide');
//                 $('#tooltip-inspection').popover('toggle');
//             }
//         );

//         $('#tooltip-cooling').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Cooling <a class='btn btn--small'><span>launch</span></a>",
//             content: `Allowing products to stabilize after depositing/sandwiching or solidify (chocolate) coating and/or decorations.`,
//         });

//         $('#tooltip-inspection').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Vision selector <a class='btn btn--small'><span>launch</span></a>",
//             content: `To improve the total line efficiency, all products are inspected by the vision selector.`,
//         });

//         $('#tooltip-buffering').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Inflow buffer <a class='btn btn--small'><span>launch</span></a>",
//             content: `The Inflow buffer system is able to temporarily absorb products that cannot be taken by the downstream equipment.`,
//         });

//         $('#tooltip-distribution').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Flowmaster <a class='btn btn--small'><span>launch</span></a>",
//             content: `The Flomaster product distribution system is distributing the product flow ‘on demand’ to the multi-leg downstream equipment.`
//         });

//         $('#tooltip-flowrapping').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Flexwrap <a class='btn btn--small'><span>launch</span></a>",
//             content: `The flex wrap magnetic linear drive chain receives slugs from the Slugmaster pocket and accelerates them to the actual continuous speed and pitch of the connected and integrated flowrapper.`,
//         });

//         $('#tooltip-loading').popover({
//             //placement: "bottom",
//             container: ".popover__container",
//             html: true,
//             title: "<a class='btn btn--big' href='/detail'>View system</a> Gradomatic <a class='btn btn--small'><span>launch</span></a>",
//             content: `The Gradomatic is able to handle a random supply of products, blending the production and creating counted slugs.`,
//         });

//         window.addEventListener("load", function () {
//             var url = window.location.href;

//             if (url.indexOf("#loading") > -1) {
//                 $('#tooltip-loading').popover('show');
//             } else if (url.indexOf("#cooling") > -1) {
//                 $('#tooltip-cooling').popover('show');
//             } else if (url.indexOf("#distribution") > -1) {
//                 $('#tooltip-distribution').popover('show');
//             } else if (url.indexOf("#buffering") > -1) {
//                 $('#tooltip-buffering').popover('show');
//             } else if (url.indexOf("#inspection") > -1) {
//                 $('#tooltip-inspection').popover('show');
//             } else if (url.indexOf("#flowrapping") > -1) {
//                 $('#tooltip-flowrapping').popover('show');
//             }
//         });
//         */
// }
